import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import KeepSessionAlive from '@shared/components/src/KeepSessionAlive'

import { Paper, Stack } from '@mui-components'

import Content from './components/Content'
import Description from './components/Description'
import Header from './components/Header'
import Title from './components/Title'
import { getInitialValues, validationSchema } from './SOPsUpdate.utils'

export default function Update({ data, onSubmit, onClose }) {
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getInitialValues(data),
  })

  // Reset form when data changes
  // Analogue for enableReinitialize = true
  // Can be replaced with separate loading state if needed
  useEffect(() => {
    form.reset(getInitialValues(data))
  }, [data, form])

  const isSubmitting = form.formState.isSubmitting
  const isDirty = form.formState.isDirty

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Paper variant="outlined" sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Header isDirty={isDirty} isSubmitting={isSubmitting} onCancel={onClose} />
            <Title isSubmitting={isSubmitting} />
            <Description isSubmitting={isSubmitting} />
            <Content isSubmitting={isSubmitting} />
          </Stack>
        </Paper>
      </form>
    </FormProvider>
  )
}
