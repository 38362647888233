// Convert value to string if it's a number
const stringifyKey = (key) => (typeof key === 'number' ? key.toString() : key)

// Convert all numbers in the key to strings
const keyToString = (key = []) => key.map(stringifyKey)

// Wrap key for additional processing
const key = (...args) => keyToString(args)

// Helper function to create 'lists' and 'list' keys
const createIndexesKeys = (...path) => ({
  lists: key(...path, 'lists'),
  list: (query) => key(...path, 'lists', query),
})

const QueryKeys = {
  me: (id) => ({
    details: key('me', id, 'details'),
  }),

  // ===================== ADMIN =====================
  admin: {
    metrics: createIndexesKeys('admin', 'metrics'),
    cbos: {
      ...createIndexesKeys('admin', 'cbos'),
      id: (cboId) => ({
        details: key('admin', 'cbos', cboId, 'details'),
      }),
    },
    providers: {
      ...createIndexesKeys('admin', 'providers'),
      id: (providerId) => ({
        details: key('admin', 'providers', providerId, 'details'),
      }),
    },
    systemUsers: {
      ...createIndexesKeys('admin', 'system-users'),
      id: (userId) => ({
        details: key('admin', 'system-users', userId, 'details'),
      }),
    },
  },

  // ========================= CALLS ========================
  calls: {
    ...createIndexesKeys('calls'),
    token: (query) => key('calls', 'token', query),
  },

  // ===================== PLATFORM =====================
  platform: {
    notifications: {
      ...createIndexesKeys('platform', 'notifications'),
      id: (notificationId) => ({
        details: key('platform', 'notifications', notificationId, 'details'),
      }),
    },
    blackouts: createIndexesKeys('platform', 'blackouts'),
    businessHours: key('platform', 'business-hours'),
    states: {
      id: (id) => ({
        gaps: {
          availability: createIndexesKeys('platform', 'states', id, 'gaps', 'availability'),
        },
      }),
    },
  },

  // ==================== ENCOUNTER TYPES ====================
  encounterTypes: {
    id: (encounterTypeId) => ({
      details: key('encounter-types', encounterTypeId, 'details'),
    }),
  },

  // ======================= Q PHRASES ======================
  qPhrases: createIndexesKeys('q-phrases'),

  // ========================= SOPS =====================
  sops: {
    ...createIndexesKeys('sops'),
    id: (sopId) => ({
      details: key('sops', sopId, 'details'),
    }),
  },

  // ======================== TASKS ========================
  tasks: {
    ...createIndexesKeys('tasks'),
    id: (taskId) => ({
      details: key('tasks', taskId, 'details'),
      notes: createIndexesKeys('tasks', taskId, 'notes'),
      documents: createIndexesKeys('tasks', taskId, 'documents'),
    }),
  },

  // ===================== APPOINTMENTS =====================
  appointments: {
    ...createIndexesKeys('appointments'),
    id: (appointmentId) => ({
      details: key('appointments', appointmentId, 'details'),
      notes: createIndexesKeys('appointments', appointmentId, 'notes'),
      labs: createIndexesKeys('appointments', appointmentId, 'labs'),
      prescriptions: createIndexesKeys('appointments', appointmentId, 'prescriptions'),
      preview: {
        soapNote: key('appointments', appointmentId, 'preview', 'soap_note'),
        avs: key('appointments', appointmentId, 'preview', 'avs'),
      },
    }),
  },

  // =================== CONVERSATIONS ===================
  conversations: {
    ...createIndexesKeys('conversations'),
    betweenUsers: (query) => key('conversations', 'between-users', query),
    search: (query) => key('conversations', 'search', query),
    id: (conversationId) => ({
      details: key('conversations', conversationId, 'details'),
      messages: {
        ...createIndexesKeys('conversations', conversationId, 'messages'),
        id: (messageId) => ({
          details: key('conversations', conversationId, 'messages', messageId, 'details'),
        }),
      },
      behalfUsers: createIndexesKeys('conversations', conversationId, 'behalf-of-users'),
      users: {
        search: createIndexesKeys('conversations', conversationId, 'users', 'search'),
      },
    }),
  },

  // ===================== PROVIDERS =====================
  providers: {
    ...createIndexesKeys('providers'),
    availabilitiesSummary: createIndexesKeys('providers', 'availabilities-summary'),
    id: (providerId) => ({
      details: key('providers', providerId, 'details'),
      metrics: createIndexesKeys('providers', providerId, 'metrics'),
      cbos: createIndexesKeys('providers', providerId, 'cbos'),
      availabilities: {
        ...createIndexesKeys('providers', providerId, 'availabilities'),
        months: createIndexesKeys('providers', providerId, 'availabilities', 'months'),
      },
      adminTimes: createIndexesKeys('providers', providerId, 'admin-time'),
      appointments: createIndexesKeys('providers', providerId, 'appointments'),
      adHocAppointments: createIndexesKeys('providers', providerId, 'ad-hoc-appointments'),
      completedEncounters: createIndexesKeys('providers', providerId, 'completed-encounters'),
      dashboard: {
        labs: createIndexesKeys('providers', providerId, 'dashboard', 'labs'),
        appointments: createIndexesKeys('providers', providerId, 'dashboard', 'appointments'),
        assessments: createIndexesKeys('providers', providerId, 'dashboard', 'assessments'),
        parTasks: createIndexesKeys('providers', providerId, 'dashboard', 'par-tasks'),
      },
    }),
  },

  // ===================== CBOS =====================
  cbos: {
    ...createIndexesKeys('cbos'),
    coverage: key('cbos', 'coverage'),
    id: (cboId) => ({
      details: key('cbos', cboId, 'details'),
      ruleSets: key('cbos', cboId, 'rule-sets'),
      metrics: createIndexesKeys('cbos', cboId, 'metrics'),
      providers: createIndexesKeys('cbos', cboId, 'providers'),
      gaps: {
        availability: createIndexesKeys('cbos', cboId, 'gaps', 'availability'),
        weekly: createIndexesKeys('cbos', cboId, 'gaps', 'weekly'),
      },
      contractedPharmacies: createIndexesKeys('cbos', cboId, 'contracted-pharmacies'),
      patients: {
        former: {
          ...createIndexesKeys('cbos', cboId, 'former-patients'),
          id: (patientId) => ({
            details: key('cbos', cboId, 'former-patients', patientId, 'details'),
            encounters: createIndexesKeys('cbos', cboId, 'former-patients', patientId, 'encounters'),
          }),
        },
      },
    }),
  },

  // =================== PHARMACIES ===================
  pharmacies: {
    ...createIndexesKeys('pharmacies'),
    tasks: {
      ...createIndexesKeys('pharmacies', 'tasks'),
      details: key('pharmacies', 'tasks', 'details'),
      id: (taskId) => ({
        details: key('pharmacies', 'tasks', 'details', taskId),
      }),
    },
    id: (pharmacyId) => ({
      details: key('pharmacies', pharmacyId, 'details'),
    }),
  },

  // ===================== LABS =====================
  labs: {
    ...createIndexesKeys('labs'),
    id: (labId) => ({
      details: key('labs', labId, 'details'),
      notes: createIndexesKeys('labs', labId, 'notes'),
    }),
    unmatched: {
      ...createIndexesKeys('labs', 'unmatched'),
      id: (labId) => ({
        details: key('labs', 'unmatched', labId, 'details'),
      }),
    },
  },

  // ==================== UNMATCHED LABS ===================
  unmatchedLabs: {
    ...createIndexesKeys('unmatched-labs'),
    id: (labId) => ({
      details: key('unmatched-labs', labId, 'details'),
    }),
  },

  // ===================== PRESCRIPTIONS =====================
  prescriptions: {
    ...createIndexesKeys('prescriptions'),
    report: key('prescriptions', 'report'),
    id: (prescriptionId) => ({
      details: key('prescriptions', prescriptionId, 'details'),
      notes: createIndexesKeys('prescriptions', prescriptionId, 'notes'),
    }),
  },

  // ===================== ASSESSMENTS =====================
  assessments: {
    ...createIndexesKeys('assessments'),
    pending: createIndexesKeys('assessments', 'pending'),
  },

  // ===================== USERS =====================
  users: {
    ...createIndexesKeys('users'),
    id: (userId) => ({
      details: key('users', userId, 'details'),
      card: key('users', userId, 'card'),
      mentions: createIndexesKeys('users', userId, 'mentions'),
      notifications: createIndexesKeys('users', userId, 'notifications'),
      pharmacies: createIndexesKeys('users', userId, 'pharmacies'),
      settings: createIndexesKeys('users', userId, 'settings'),
      tours: {
        ...createIndexesKeys('users', userId, 'tours'),
        id: (tourId) => ({
          details: key('users', userId, 'tours', tourId, 'details'),
        }),
      },
    }),
  },

  // ================== SYSTEM USERS =====================
  systemUsers: {
    ...createIndexesKeys('system-users'),
    id: (userId) => ({
      details: key('system-users', userId, 'details'),
    }),
  },

  // ==================== PATIENTS ====================
  patients: {
    ...createIndexesKeys('patients'),
    healthHistoryAlerts: createIndexesKeys('patients', 'health-history-alerts'),
    id: (patientId) => ({
      details: key('patients', patientId, 'details'),
      photoID: key('patients', patientId, 'photo-id'),
      accessible: key('patients', patientId, 'accessible'),
      internalConversation: key('patients', patientId, 'internal-conversation'),
      assessments: {
        ...createIndexesKeys('patients', patientId, 'assessments'),
        id: (assessmentId) => ({
          details: key('patients', patientId, 'assessments', assessmentId, 'details'),
        }),
      },
      todos: createIndexesKeys('patients', patientId, 'todos'),
      availabilities: createIndexesKeys('patients', patientId, 'availabilities'),
      documents: {
        ...createIndexesKeys('patients', patientId, 'documents'),
        id: (documentId) => ({
          details: key('patients', patientId, 'documents', documentId, 'details'),
        }),
      },
      insurances: {
        ...createIndexesKeys('patients', patientId, 'insurances'),
        summary: key('patients', patientId, 'insurances', 'summary'),
        id: (insuranceId) => ({
          details: key('patients', patientId, 'insurances', insuranceId, 'details'),
        }),
      },
      prescriptions: {
        ...createIndexesKeys('patients', patientId, 'prescriptions'),
        id: (prescriptionId) => ({
          details: key('patients', patientId, 'prescriptions', prescriptionId, 'details'),
        }),
      },
      labs: {
        ...createIndexesKeys('patients', patientId, 'labs'),
        file: (fileId) => key('patients', patientId, 'labs', 'file', fileId),
        vendors: createIndexesKeys('patients', patientId, 'lab-vendors'),
        id: (labId) => ({
          details: key('patients', patientId, 'labs', labId, 'details'),
          notes: createIndexesKeys('patients', patientId, 'labs', labId, 'notes'),
        }),
      },
      roiMedicalRecords: {
        id: (medicalRecordId) => ({
          details: key('patients', patientId, 'roi-medical-records', medicalRecordId, 'details'),
        }),
      },
      events: createIndexesKeys('patients', patientId, 'events'),
      treatments: createIndexesKeys('patients', patientId, 'treatments'),
      gilead: {
        id: (gileadApplicationId) => ({
          details: key('patients', patientId, 'gilead-applications', gileadApplicationId, 'details'),
        }),
      },
      roiRequest: {
        id: (roiRequestId) => ({
          details: key('patients', patientId, 'roi-requests', roiRequestId, 'details'),
        }),
      },
      questionnaires: key('patients', patientId, 'questionnaires'),
      cbos: createIndexesKeys('patients', patientId, 'cbos'),
      availableServiceLines: createIndexesKeys('patients', patientId, 'available-service-lines'),
      providers: createIndexesKeys('patients', patientId, 'providers'),
      notes: createIndexesKeys('patients', patientId, 'notes'),
      tasks: {
        ...createIndexesKeys('patients', patientId, 'tasks'),
        id: (taskId) => ({
          details: key('patients', patientId, 'tasks', taskId, 'details'),
          notes: createIndexesKeys('patients', patientId, 'tasks', taskId, 'notes'),
        }),
      },
      appointments: {
        ...createIndexesKeys('patients', patientId, 'appointments'),
        id: (appointmentId) => ({
          details: key('patients', patientId, 'appointments', appointmentId, 'details'),
          soapSchema: key('patients', patientId, 'appointments', appointmentId, 'soap-schema'),
        }),
      },
      encounters: {
        ...createIndexesKeys('patients', patientId, 'encounters'),
        available: createIndexesKeys('patients', patientId, 'encounters', 'available'),
        id: (encounterId) => ({
          details: key('patients', patientId, 'encounters', encounterId, 'details'),
          notes: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'notes'),
          vaccines: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'vaccines'),
          appointments: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'appointments'),
          availabilities: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'availabilities'),
          labs: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'labs'),
          prescriptions: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'prescriptions'),
          referrals: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'referrals'),
          assessments: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'assessments'),
          morbidities: createIndexesKeys('patients', patientId, 'encounters', encounterId, 'doh-morbidities'),
        }),
      },
      healthHistory: {
        alerts: createIndexesKeys('patients', patientId, 'health-history', 'alerts'),
        type: (type) => createIndexesKeys('patients', patientId, 'health-history', type),
        notes: createIndexesKeys('patients', patientId, 'health-history', 'notes'),
      },
    }),
  },

  // ===================== AVAILABILITIES =====================
  availabilities: {
    logs: createIndexesKeys('availabilities', 'logs'),
    declined: createIndexesKeys('availabilities', 'declined'),
    providers: {
      ...createIndexesKeys('availabilities', 'providers'),
      id: (providerId) => ({
        pending: createIndexesKeys('availabilities', 'providers', providerId, 'pending'),
      }),
    },
  },
}

export default QueryKeys
